import React from "react"

const BenefitsSection = () => {
  return (
    <div id="benefits" className="bg-gray-100 w-full px-4 pt-24 pb-32">

      <div className="w-full max-w-2xl mx-auto">
        <h2 className="text-center uppercase">Benefits</h2>
        <h2 className="text-4xl text-center text-gray-800 font-semibold uppercase mt-6">Static Website, But Better</h2>
        <p className="text-xl text-center mt-8">Static websites are fast and secure. <a href='https://www.gatsbyjs.com' target='_blank' rel='noopener noreferrer' className='font-medium underline'>GatsbyJS</a> is a React-based static site generator. With Gatsby, you'll get static site benefits and more.</p>
      </div>

      <div className="w-full max-w-screen-xl mx-auto mt-16">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 xl:gap-12">
          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center p-6">
              <svg className="fill-current text-site-green block h-8 w-8 my-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm1-5h1a3 3 0 0 0 0-6H7.99a1 1 0 0 1 0-2H14V5h-3V3H9v2H8a3 3 0 1 0 0 6h4a1 1 0 1 1 0 2H6v2h3v2h2v-2z"/></svg>
              <h2 className="font-display font-semibold text-center text-2xl mb-4">Better Conversion Rates</h2>
              <p className="font-body text-gray-800 text-base text-center mb-4">
                Gatsby makes your site fast by default—improving lead generation, higher revenue and great customer experience.
              </p>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center p-6">
              <svg className="fill-current text-site-green block h-8 w-8 my-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/></svg>
              <h2 className="font-display font-semibold text-center text-2xl mb-4">Optimized for SEO</h2>
              <p className="font-body text-gray-800 text-base text-center mb-4">
                Google aligns speed as success in search ranking. Your Gatsby site will help you rise toward the top rank for terms that matter.
              </p>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center p-6">
              <svg className="fill-current text-site-green block h-8 w-8 my-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V0L8.11 5.87 3 12h4v8L17 8h-4z"/></svg>
              <h2 className="font-display font-semibold text-center text-2xl mb-4">Fast on Mobile</h2>
              <p className="font-body text-gray-800 text-base text-center mb-4">
                When it comes to mobile sites, speed and size matter. We make sure your site is fast on mobile, not just on desktop.
              </p>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center p-6">
              <svg className="fill-current text-site-green block h-8 w-8 my-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm-5.6-4.29a9.95 9.95 0 0 1 11.2 0 8 8 0 1 0-11.2 0zm6.12-7.64l3.02-3.02 1.41 1.41-3.02 3.02a2 2 0 1 1-1.41-1.41z"/></svg>
              <h2 className="font-display font-semibold text-center text-2xl mb-4">Better Performance</h2>
              <p className="font-body text-gray-800 text-base text-center mb-4">
                Gatsby takes fast to a whole new level with websites that are pre-built and <a href='https://www.cloudflare.com/learning/serverless/glossary/what-is-edge-computing/' target='_blank' rel='noopener noreferrer' className='font-medium underline'>live on the edge</a>—right where your customers are.
              </p>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center p-6">
              <svg className="fill-current text-site-green block h-8 w-8 my-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"/></svg>
              <h2 className="font-display font-semibold text-center text-2xl mb-4">Cheaper, Easier Scaling</h2>
              <p className="font-body text-gray-800 text-base text-center mb-4">
                Gatsby sites are <a href='https://web.dev/content-delivery-networks/' target='_blank' rel='noopener noreferrer' className='font-medium underline'>deployable to a CDN</a>, which is much less expensive than traditional website hosting.
              </p>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center p-6">
              <svg className="fill-current text-site-green block h-8 w-8 my-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M19 11a7.5 7.5 0 0 1-3.5 5.94L10 20l-5.5-3.06A7.5 7.5 0 0 1 1 11V3c3.38 0 6.5-1.12 9-3 2.5 1.89 5.62 3 9 3v8zm-9 1.08l2.92 2.04-1.03-3.41 2.84-2.15-3.56-.08L10 5.12 8.83 8.48l-3.56.08L8.1 10.7l-1.03 3.4L10 12.09z"/></svg>
              <h2 className="font-display font-semibold text-center text-2xl mb-4">Better Security</h2>
              <p className="font-body text-gray-800 text-base text-center mb-4">
                Gatsby is <a href='https://www.cloudflare.com/learning/performance/what-is-jamstack/' target='_blank' rel='noopener noreferrer' className='font-medium underline'>Jamstack</a>. By design, Jamstack architecture separates the frontend and backend to reduced attack vectors.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BenefitsSection