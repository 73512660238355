import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const Triangle = styled.div`
  position: absolute;
  left: 40px;
  bottom: -30px;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 70px 0 0;
  border-color: #ffffff transparent transparent transparent;
`

const HomeTestimonialsSection = () => {
  return (
    <div id="testimonials" className="w-full bg-gray-200 px-4 pt-24 pb-32">

      <div className='flex flex-col items-center w-11/12 mx-auto'>
        <h2 className="text-center uppercase">Testimonials</h2>
        <h2 className="text-4xl text-center text-gray-800 font-semibold uppercase mt-6">Don’t take our word for it.</h2>

        <p className='font-body w-full lg:w-1/2 2xl:w-4/12 text-xl text-center mt-8 mb-16'>Here’s what our clients are saying about the fastweb.dev team:</p>

        <div className='max-w-screen-xl grid grid-cols-1 lg:grid-cols-3 gap-24 lg:gap-12 px-0 lg:px-4'>
          <div>
            <div className='relative bg-white p-8 lg:p-10'>
              <q className='text-gray-900 text-lg italic'>Working with the fastweb.dev team has made my life SO EASY. We went through a full rebrand and the website is by far my favorite part. A website redo can be a lot of work but they made it effortless. They worked with me to make all my design dreams come true, took my vision, improved it, and brought it to life to create a user friendly, incredibly fast and scalable website.</q>
              <Triangle />
            </div>

            <div className='flex items-center mt-12'>
              <StaticImage
                alt="SRA 831(b) ADMIN logo"
                src="../../images/comp-logos/sra-logo-for-testi.png"
                width={100}
                formats={["auto", "webp", "avif"]}
                className="bg-white"
              />
              <h4 className='text-gray-800 text-lg ml-4'><b>Bre Cohen</b> <br/> SRA 831(b) ADMIN </h4>
            </div>
          </div>
          <div>
            <div className='relative bg-white p-8 lg:p-10'>
              <q className='text-gray-900 text-lg italic'>We hired the fastweb.dev team to help us launch our new website, and we absolutely love it! Our old website, while having great content, just wasn’t where we wanted it to be; it was quite a bit outdated. They were very responsive to our requests and promptly worked with us to achieve exactly what we wanted and more. We were able to start the new year with a fresh, modern website that we truly enjoy. Showing our website to our clients and potential clients brings us great pride!</q>
              <Triangle />
            </div>

            <div className='flex items-center mt-12'>
              <StaticImage
                alt="Brion Jeannette Architecture logo"
                src="../../images/comp-logos/bja-logo-for-testi-new.png"
                width={100}
                formats={["auto", "webp", "avif"]}
                className="bg-white"
              />
              <h4 className='text-gray-800 text-lg ml-4'><b>Kassandra Mar</b> <br/> Brion Jeannette Architecture </h4>
            </div>
          </div>
          <div>
            <div className='relative bg-white p-8 lg:p-10'>
              <q className='text-gray-900 text-lg italic'>It was extremely pleasant working with the fastweb.dev team. They completed our company website in a timely manner as promised. They were easy to work with, walked me through the process step by step, and the website is exactly what we had asked for. They developed the website UNDER the quoted cost which shows me they are trustworthy and I can rely on them in the future for any web design changes. I would highly recommend them for your website design.</q>
              <Triangle />
            </div>

            <div className='flex items-center mt-12'>
              <StaticImage
                alt="Tiarna Real Estate Services logo"
                src="../../images/comp-logos/tiarna-logo-for-testi.png"
                width={100}
                formats={["auto", "webp", "avif"]}
              />
              <h4 className='text-gray-800 text-lg ml-4'><b>Sonya Cavanaugh</b> <br/> Tiarna Real Estate Services </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeTestimonialsSection