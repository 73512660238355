import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ClientsSectionNew = () => {
  return (
    <div id="clients" className="bg-gray-800 w-full px-4 pt-24 pb-32">

      <div className="w-full max-w-2xl mx-auto">
        <h2 className="text-center text-gray-200 uppercase">Featured Clients</h2>
        <h2 className="text-4xl text-center text-white font-semibold uppercase mt-6">Who We Partner With</h2>
        <p className="text-xl text-center text-white mt-8">We're the secret weapon of smart businesses, enterprising startups and sassy agencies.</p>
      </div>

      <div className="w-full max-w-screen-xl mx-auto mt-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 xl:gap-12">
          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center px-8 pt-12 pb-8">
              <div className="h-20 flex-col items-center justify-center">
                <StaticImage
                  src="../../images/comp-logos/sra.png"
                  width={200}
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt=""
                  className="w-52"
                />
              </div>
              <p className="font-body text-gray-800 text-base text-center mt-4 mb-4">
                SRA helps businesses manage risk more effectively with tax-deferred dollars.
              </p>
              <p className="font-body text-gray-800 text-base text-center mb-4 italic">
                From WordPress to Gatsby
              </p>
              <a href="https://www.831b.com/" target="_blank" rel="noopener noreferrer">
                <button className="w-full bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white px-6 py-2 my-3 font-semibold uppercase tracking-wider shadow-md">
                  View site
                </button>
              </a>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center px-8 pt-12 pb-8">
              <div className="h-20 flex-col items-center justify-center">
                <StaticImage
                  src="../../images/comp-logos/CommandAccess.png"
                  width={200}
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt=""
                  className="w-52"
                />
              </div>
              <p className="font-body text-gray-800 text-base text-center mt-4 mb-4">
                Command Access is a manufacturer of high-quality electrified door hardware.
              </p>
              <p className="font-body text-gray-800 text-base text-center mb-4 italic">
                From WordPress to Gatsby
              </p>
              <a href="https://www.commandaccess.com/" target="_blank" rel="noopener noreferrer">
                <button className="w-full bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white px-6 py-2 my-3 font-semibold uppercase tracking-wider shadow-md">
                  View site
                </button>
              </a>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center px-8 pt-12 pb-8">
              <div className="h-20 flex-col items-center justify-center">
                <StaticImage
                  src="../../images/comp-logos/grolens.jpeg"
                  width={200}
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt=""
                  className="w-52"
                />
              </div>
              <p className="font-body text-gray-800 text-base text-center mt-4 mb-4">
                GroLens is the leading all-in-one seed to sale software for commercial growers.
              </p>
              <p className="font-body text-gray-800 text-base text-center mb-4 italic">
                New Gatsby website
              </p>
              <a href="https://grolens.com/" target="_blank" rel="noopener noreferrer">
                <button className="w-full bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white px-6 py-2 my-3 font-semibold uppercase tracking-wider shadow-md">
                  View site
                </button>
              </a>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center px-8 pt-12 pb-8">
              <div className="h-20 flex-col items-center justify-center">
                <StaticImage
                  src="../../images/comp-logos/hirelive-logo.png"
                  width={200}
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt=""
                  className="w-52"
                />
              </div>
              <p className="font-body text-gray-800 text-base text-center mt-4 mb-4">
                HireLive brings together Fortune 1000 companies face to face with top candidates in all industries across the United States.
              </p>
              <p className="font-body text-gray-800 text-base text-center mb-4 italic">
                From WordPress to Gatsby
              </p>
              <a href="https://www.hirelive.com/" target="_blank" rel="noopener noreferrer">
                <button className="w-full bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white px-6 py-2 my-3 font-semibold uppercase tracking-wider shadow-md">
                  View site
                </button>
              </a>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center px-8 pt-12 pb-8">
              <div className="h-20 flex-col items-center justify-center">
                <StaticImage
                  src="../../images/comp-logos/nwp-logo.png"
                  width={200}
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt=""
                  className="w-52"
                />
              </div>
              <p className="font-body text-gray-800 text-base text-center mt-4 mb-4">
                National Wood Products: Your Hardwood Lumber, Melamine, MDF, Acrylic & Plywood Specialist in Southern California
              </p>
              <p className="font-body text-gray-800 text-base text-center mb-4 italic">
                From WordPress to Gatsby
              </p>
              <a href="https://www.nwpsocal.com/" target="_blank" rel="noopener noreferrer">
                <button className="w-full bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white px-6 py-2 my-3 font-semibold uppercase tracking-wider shadow-md">
                  View site
                </button>
              </a>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center px-8 pt-12 pb-8">
              <div className="h-20 flex-col items-center justify-center">
                <StaticImage
                  src="../../images/comp-logos/itproactive-logo.png"
                  width={200}
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt=""
                  className="w-52"
                />
              </div>
              <p className="font-body text-gray-800 text-base text-center mt-4 mb-4">
                Full Service Managed IT Services, Network Administration and IT Support for Orange County, CA
              </p>
              <p className="font-body text-gray-800 text-base text-center mb-4 italic">
                From WordPress to Gatsby
              </p>
              <a href="https://www.itproactive.com/" target="_blank" rel="noopener noreferrer">
                <button className="w-full bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white px-6 py-2 my-3 font-semibold uppercase tracking-wider shadow-md">
                  View site
                </button>
              </a>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center px-8 pt-12 pb-8">
              <div className="h-20 flex-col items-center justify-center">
                <StaticImage
                  src="../../images/comp-logos/brion-jeannette-architecture-logo-white.jpg"
                  width={200}
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt=""
                  className="w-60 rounded-lg"
                />
              </div>
              <p className="font-body text-gray-800 text-base text-center mt-4 mb-4">
                Brion Jeannette Architecture of Newport Beach, California, is a premiere residential architecture firm specializing in organic and energy conscious design for Coastal California homes and estates.
              </p>
              <p className="font-body text-gray-800 text-base text-center mb-4 italic">
                New Gatsby website
              </p>
              <a href="https://www.customarchitecture.com/" target="_blank" rel="noopener noreferrer">
                <button className="w-full bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white px-6 py-2 my-3 font-semibold uppercase tracking-wider shadow-md">
                  View site
                </button>
              </a>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center px-8 pt-12 pb-8">
              <div className="h-20 flex-col items-center justify-center">
                <StaticImage
                  src="../../images/comp-logos/iam-logo.png"
                  width={200}
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt=""
                  className="w-52"
                />
              </div>
              <p className="font-body text-gray-800 text-base text-center mt-4 mb-4">
                Interpacific Asset Management provides superior management services to community associations, commercial and residential properties throughout Southern California.
              </p>
              <p className="font-body text-gray-800 text-base text-center mb-4 italic">
                New Gatsby website
              </p>
              <a href="https://www.interpacificmgmt.com/" target="_blank" rel="noopener noreferrer">
                <button className="w-full bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white px-6 py-2 my-3 font-semibold uppercase tracking-wider shadow-md">
                  View site
                </button>
              </a>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center px-8 pt-12 pb-8">
              <div className="h-20 flex-col items-center justify-center">
                <StaticImage
                  src="../../images/comp-logos/tiarna-logo.png"
                  width={200}
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt=""
                  className="w-52"
                />
              </div>
              <p className="font-body text-gray-800 text-base text-center mt-4 mb-4">
                Tiarna Real Estate Services, Inc. is a comprehensive commercial real estate firm serving landlords in California — specializing in office, industrial and retail properties in California.
              </p>
              <p className="font-body text-gray-800 text-base text-center mb-4 italic">
                New Gatsby website
              </p>
              <a href="https://www.tiarna.com/" target="_blank" rel="noopener noreferrer">
                <button className="w-full bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white px-6 py-2 my-3 font-semibold uppercase tracking-wider shadow-md">
                  View site
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientsSectionNew