import React from "react"

const TestimonialOneSection = () => {
  return (
    <div className="w-full bg-gray-200 px-4 py-32">

      <div className="w-full max-w-screen-lg mx-auto">
        <svg className="fill-current text-site-green block h-12 w-16 mb-4 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.928,3.932A9.709,9.709,0,0,0,.5,13.88v1.243a4.942,4.942,0,1,0,4.941-4.941,4.609,4.609,0,0,0-1.115.14.25.25,0,0,1-.277-.368A6.832,6.832,0,0,1,9.928,6.432a1.25,1.25,0,0,0,0-2.5Z"></path>
          <path d="M22.25,6.432a1.25,1.25,0,0,0,0-2.5,9.71,9.71,0,0,0-9.428,9.948v1.243a4.942,4.942,0,1,0,4.942-4.941,4.611,4.611,0,0,0-1.116.14.249.249,0,0,1-.26-.092.252.252,0,0,1-.017-.276A6.832,6.832,0,0,1,22.25,6.432Z"></path>
        </svg>
        <h2 className="hidden md:block text-4xl text-gray-900 text-center font-semibold leading-normal">Performance plays a major role in the success of any online venture. High-performing sites engage and retain users better than low-performing ones.</h2>
        <h2 className="md:hidden text-2xl text-gray-900 text-center font-semibold leading-normal">Performance plays a major role in the success of any online venture. High-performing sites engage and retain users better than low-performing ones.</h2>
        <p className="text-center mt-6">— <a href="https://web.dev/why-speed-matters/" target="_blank" rel="noopener noreferrer" className="underline">Google Developers</a></p>
      </div>
    </div>
  )
}

export default TestimonialOneSection