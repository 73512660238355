import React from "react"
import { Link } from 'gatsby'
import { navigate } from '@reach/router'

const HomeHeroSectionUpdate = () => (
  <div className="bg-gray-900 flex justify-center items-center px-4 pt-36 xl:pt-52 pb-24 xl:pb-48">
    <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto'>
      <div className='w-full md:w-10/12 xl:w-2/3 flex flex-col items-center mx-auto'>
        <h1 className='text-xs text-gray-200 uppercase text-center tracking-widest leading-tight'>Website Design/Redesign & Development Services</h1>

        <h2 className='text-4xl md:text-5xl font-medium text-white text-center uppercase mt-6'>
          <span className='leading-tight'>Dealing with the Complexity of <span className="text-site-green">Website Development</span> is our Business</span>
        </h2>

        <h2 className='w-full text-lg lg:text-xl text-white text-center mt-6'>So you can spend more time on what matters most — <span className="font-bold border-b-2 border-site-green">YOUR BUSINESS</span></h2>

        <h2 className='w-full lg:w-9/12 text-xl lg:text-2xl text-white text-center mt-6'>We make it <span className="font-bold text-site-green">FAST & EASY</span> for you</h2>

        <div className='flex flex-col xl:flex-row items-center mt-8'>
          <Link to="/contact">
            <button
              className='font-body text-lg bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white font-semibold py-2 px-8 mr-0 xl:mr-3 mb-4 uppercase tracking-wider'
            >
              Get Free Quote
            </button>
          </Link>

          <button
            className='font-body text-lg border-2 border-site-green hover:bg-site-green text-site-green hover:text-white font-semibold py-2 px-8 mr-0 xl:mr-3 mb-4 uppercase tracking-wider'
            onClick={event => {
              event.preventDefault()
              navigate("/#testimonials")
            }}
          >
            Read Proof
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default HomeHeroSectionUpdate