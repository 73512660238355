import React from "react"

import Layout from "../components/layout/layout"
import Seonew from "../components/seo-new"
import HomeHeroSectionUpdate from "../components/sections/home-hero-section-update"
import BenefitsSection from "../components/sections/benefits-section"
import TestimonialOneSection from "../components/sections/testimonial-one-section"
import ServicesSectionNew from "../components/sections/services-section-new"
import ClientsSectionNew from "../components/sections/clients-section-new"
import CTASection from "../components/sections/cta-section"
import HomeTestimonialsSection from "../components/sections/home-testimonials-section"

const IndexPage = () => (
  <Layout>
    <Seonew
      title="Website Design & Development Agency - No Contracts. Just Results."
      description="Jamstack website design & development is our forte. We build blazing fast and secure website for serious business."
    />
    <HomeHeroSectionUpdate />
    <BenefitsSection />
    <TestimonialOneSection />
    <ServicesSectionNew />
    <ClientsSectionNew />
    <HomeTestimonialsSection />
    <CTASection />
  </Layout>
)

export default IndexPage
