import React from "react"
import { Link } from "gatsby"

const ServicesSectionNew = () => {
  return (
    <div id="services" className="bg-gray-100 w-full px-4 pt-24 pb-32">

      <div className="w-full max-w-2xl mx-auto">
        <h2 className="text-center uppercase">Services</h2>
        <h2 className="text-4xl text-center text-gray-800 font-semibold uppercase mt-6">No Contracts. Just Results.</h2>
        <p className="text-xl text-center mt-8">We're a small Jamstack and Gatsby web design/redesign & development company that don’t do contracts. We rely on our results to be all the reason you need to work with us.</p>
      </div>

      <div className="w-full max-w-screen-xl mx-auto mt-16">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 xl:gap-12">
          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center p-6">
              <svg className="fill-current text-site-green block h-8 w-8 my-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M 18,0 H 6 C 4.9,0 4,0.9 4,2 V 4 H 2 C 0.9,4 0,4.9 0,6 v 12 c 0,1.1 0.9,2 2,2 h 12 c 1.1,0 2,-0.9 2,-2 v -2 h 2 c 1.1,0 2,-0.9 2,-2 V 2 C 20,0.9 19.1,0 18,0 Z m 0,4 H 10 V 2 h 8 z M 8,2 V 4 H 6 V 2 Z m 6,16 H 2 V 6 h 2 v 8 c 0,1.1 0.9,2 2,2 h 8 z M 6,14 V 6 h 12 v 8 z"/></svg>
              <h2 className="font-display font-semibold text-center text-3xl mb-4">Landing Site</h2>
              <p className="font-body text-gray-800 text-center text-lg mb-4">
                When your business only needs a single landing page for lead generation.
              </p>
              <Link to="/contact">
                <button className="w-full bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white px-6 py-2 my-3 font-semibold uppercase tracking-wider shadow-md">
                  Get Free Quote
                </button>
              </Link>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center p-6">
              <svg className="fill-current text-site-green block h-8 w-8 my-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M 18,0 H 6 C 4.9,0 4,0.9 4,2 V 4 H 2 C 0.9,4 0,4.9 0,6 v 12 c 0,1.1 0.9,2 2,2 h 12 c 1.1,0 2,-0.9 2,-2 v -2 h 2 c 1.1,0 2,-0.9 2,-2 V 2 C 20,0.9 19.1,0 18,0 Z m 0,4 H 10 V 2 h 8 z M 8,2 V 4 H 6 V 2 Z m 6,16 H 2 V 6 h 2 v 8 c 0,1.1 0.9,2 2,2 h 8 z M 6,14 V 6 h 12 v 8 z"/></svg>
              <h2 className="font-display font-semibold text-center text-3xl mb-4">Marketing Site</h2>
              <p className="font-body text-gray-800 text-center text-lg mb-4">
                When your business needs more pages to market your products or services.
              </p>
              <Link to="/contact">
                <button className="w-full bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white px-6 py-2 my-3 font-semibold uppercase tracking-wider shadow-md">
                  Get Free Quote
                </button>
              </Link>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center p-6">
              <svg className="fill-current text-site-green block h-8 w-8 my-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M 18,0 H 6 C 4.9,0 4,0.9 4,2 V 4 H 2 C 0.9,4 0,4.9 0,6 v 12 c 0,1.1 0.9,2 2,2 h 12 c 1.1,0 2,-0.9 2,-2 v -2 h 2 c 1.1,0 2,-0.9 2,-2 V 2 C 20,0.9 19.1,0 18,0 Z m 0,4 H 10 V 2 h 8 z M 8,2 V 4 H 6 V 2 Z m 6,16 H 2 V 6 h 2 v 8 c 0,1.1 0.9,2 2,2 h 8 z M 6,14 V 6 h 12 v 8 z"/></svg>
              <h2 className="font-display font-semibold text-center text-3xl mb-4">Site Design/Redesign</h2>
              <p className="font-body text-gray-800 text-center text-lg mb-4">
                When your business needs a modern look that attracts & converts visitors.
              </p>
              <Link to="/contact">
                <button className="w-full bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white px-6 py-2 my-3 font-semibold uppercase tracking-wider shadow-md">
                  Get Free Quote
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesSectionNew